import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Content, { HTMLContent } from "../components/content"

export const IndexPageTemplate = ({ content, picture ,contentComponent}) => {
  const PageContent = contentComponent || Content
  return(
  <div className="center">
    <h1 className="welcome">Wilkommen</h1>
    <img src={picture} width="90%" alt=""/>
    <PageContent className="content mt-5 mb-5" content={content} />
  </div>
)}

export const IndexPage = ({ data }) => {
  const { markdownRemark: info } = data
  return (
    <Layout>
      <IndexPageTemplate
        picture={info.frontmatter.picture}
        content={info.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        picture
      }
    }
  }
`
